import { ToastRefType } from './Toast'

export type ToastIconType = 'POSITIVE' | 'WARNING' | 'FAILED'

export const ToastEmojis = {
  sad: '😢',
  smile: '😅',
} as const

interface IToastMessageProps {
  /** 토스트 메시지의 key (중복 출력 방지 용도) */
  key?: string | number

  /** 좌측에 표시될 아이콘 */
  emoji?: keyof typeof ToastEmojis

  /** 토스트 종류 */
  type?: 'toast' | 'snackbar'

  /** 토스트 문구 */
  text: string

  /** 토스트 컴포넌트 ref */
  ref?: ToastRefType | null

  /** 토스트 노출 시간(밀리초) */
  durationMillisec?: number

  /** 화면 하단으로부터의 거리 */
  bottomOffset?: number

  /** 닫기 버튼 존재 여부 */
  closable?: boolean

  /** 토스트 너비 */
  width?: number

  /** 우측에 표시될 버튼 목록 */
  buttons?: {
    color?: string
    text: string
    onClick?: (closeToast: () => void) => void
    load?: boolean
  }[]
}

export default IToastMessageProps
